import {
    faFacebook,
    faInstagram,
    faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { faComment } from "@fortawesome/free-regular-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import mixpanel from "mixpanel-browser";
import React, { Component } from "react";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import checkCircle from "../../collection/images/check-circle.svg";
import "../../style/TheBrand.scss";
import "./styles.scss";
import TagManager from '@sooro-io/react-gtm-module'

class TheBrandComponentLightTemplate extends Component {
    info = [
        {
            title: this.props.t("BRAND FROM", "BRAND FROM"),
            text: this.props.theBrand.brand_from,
        },
        {
            title: "YEAR ESTABLISHED",
            text: this.props.theBrand.year_established,
        },
        {
            title: this.props.t("MULTIBRAND RETAILERS", "MULTIBRAND RETAILERS"),
            text: this.props.theBrand.multibrand_retailers,
        },
        {
            title: this.props.t(
                "MULTIBRAND RETAILERS DESCRIPTION",
                "MULTIBRAND RETAILERS DESCRIPTION"
            ),
            text: this.props.theBrand.multibrand_retailers_full,
        },
    ];

    constructor(props) {
        super(props);

        const shouldShowSendMessageForm =
            new URLSearchParams(this.props.location.search).get("action") ===
            "send-a-message";

        this.state = {
            idFilter: "",
            shouldShowSendMessageForm,
            formSubmitted: false,
            isSubmitting: false,
            emailError: "",
            nameError: "",
            storeNameError: "",
            messageError: "",
            sendError: [],
        };
    }

    closedLightBox() {
        this.setState({
            open: false,
        });
    }

    openLightBox(key) {
        this.setState({
            open: true,
            key: key,
        });
    }

    getIdFilter(id) {
        if (id === this.state.idFilter) {
            this.setState({
                idFilter: "",
            });
        } else {
            this.setState({
                idFilter: id,
            });
        }
    }

    getItemsSplitedByFive(items) {
        let items_by_5 = [];
        let i, j, temparray;
        for (i = 0, j = items.length; i < j; i += 5) {
            temparray = items.slice(i, i + 5);
            items_by_5.push(temparray);
        }
        return items_by_5;
    }

    componentDidMount() {
        if (
            process.env.REACT_APP_MIXPANEL_ENABLED === true ||
            process.env.REACT_APP_MIXPANEL_ENABLED === "true"
        ) {
            if (window.localStorage.getItem("userData")) {
                mixpanel.identify(
                    `${JSON.parse(window.localStorage.getItem("userData")).id}`
                );
            }
            mixpanel.track("Seller Page", {
                seller: this.props.brandData.name,
                seller_id: this.props.brandData.id,
                content: "ABOUT THE BRAND",
            });
        }
    }

    validateFields = (name, email, storeName, message, userSType) => {
        let isValid = true;

        this.setState({
            nameError: "",
            emailError: "",
            storeNameError: "",
            messageError: "",
        });

        if (userSType !== "buyer") {
            if (!email) {
                this.setState({ emailError: "Email address is required" });

                isValid = false;
            } else if (
                !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(email).toLowerCase())
            ) {
                this.setState({ emailError: "Invalid email address" });

                isValid = false;
            }

            if (!name) {
                this.setState({ nameError: "Name is required" });

                isValid = false;
            }

            if (!storeName) {
                this.setState({ storeNameError: "Store name is required" });

                isValid = false;
            }
        }

        if (!message) {
            this.setState({ messageError: "Message is required" });

            isValid = false;
        }

        return isValid;
    };

    sendForm = async () => {
        const userSType = localStorage.getItem("user_stype");
        const sellerId = this.props.brandData.id;

        const nameElement = document.getElementById("name");
        const emailElement = document.getElementById("email");
        const storeNameElement = document.getElementById("store_name");
        const messageElement = document.getElementById("message");

        const name = nameElement ? nameElement.value : "";
        const email = emailElement ? emailElement.value : "";
        const storeName = storeNameElement ? storeNameElement.value : "";
        const message = messageElement ? messageElement.value : "";

        if (!this.validateFields(name, email, storeName, message, userSType)) {
            return;
        }

        const headers = {
            "X-Account-Hash": localStorage.getItem("xAccountHash"),
            "Content-Type": "application/json",
        };

        let payload = { message };

        if (userSType === "buyer") {
            payload = { ...payload, seller_id: sellerId || null };
        } else {
            payload = {
                ...payload,
                name,
                email,
                store_name: storeName,
                seller_id: sellerId || null,
            };
        }
        this.setState({ isSubmitting: true });

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_NEW_API_URL}/v2/form-submissions/brand-makeabuy`,
                payload,
                { headers }
            ).then((response) => {
                // Google Tag Manager event: seller_message
                const tagManagerArgs = {
                    dataLayer: {
                        seller: this.props.brandData.name,
                        seller_id: this.props.brandData.id,
                        event: 'seller_message',
                        content: 'brand-makeabuy',
                    },
                }
                TagManager.dataLayer(tagManagerArgs)
              });

            this.setState({ formSubmitted: true, isSubmitting: false });
        } catch (error) {
            this.setState({ isSubmitting: false, sendError: error.response });
        }
    };

    render() {
        const { t, brandStyleImages, brandData } = this.props;

        const userSType = localStorage.getItem("user_stype");

        const {
            formSubmitted,
            isSubmitting,
            emailError,
            sendError,
            nameError,
            storeNameError,
            messageError,
        } = this.state;

        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>
                        {t(
                            `${brandData.name} Wholesale Products | Connect and Buy online at BLANC`
                        )}
                    </title>
                    <meta
                        name="keywords"
                        content="about, brand, fashion, buy, sell"
                    />
                    <meta http-equiv="content-language" content="en" />
                    <meta
                        name="description"
                        content="Discover the fascinating story behind our fashion brand."
                    />
                    <link
                        rel="canonical"
                        href={
                            "https://" +
                            t(
                                `${window.location.hostname}/${this.props.seller}`
                            )
                        }
                    />
                </Helmet>

                <section className="container">
                    <div className="actionsContainer">
                        <button
                            onClick={() =>
                                this.setState({
                                    shouldShowSendMessageForm: false,
                                })
                            }
                            className={
                                !this.state.shouldShowSendMessageForm
                                    ? "active"
                                    : ""
                            }
                        >
                            About the brand
                        </button>
                        <button className="outline">
                            <a
                                href={`https://blancfashion.pipedrive.com/scheduler/ql9z4vs59/meeting-at-coterie-ny`}
                                target="_blank"
                            >
                                book a meeting
                            </a>
                        </button>
                        <button
                            onClick={() =>
                                this.setState({
                                    shouldShowSendMessageForm: true,
                                })
                            }
                            className={
                                (this.state.shouldShowSendMessageForm
                                    ? "active"
                                    : "") + " primary"
                            }
                        >
                            Send a message
                        </button>
                    </div>

                    {this.state.shouldShowSendMessageForm ? (
                        <div
                            className={`no-collection messageForm ${
                                !brandData.the_brand.brand_style_images[0]
                                    ? "no-image"
                                    : ""
                            }`}
                        >
                            {brandData.the_brand.brand_style_images[0] && (
                                <div className="container-image">
                                    <img
                                        src={
                                            brandData.the_brand
                                                .brand_style_images[0]
                                                .image_original
                                        }
                                        alt=""
                                    />
                                </div>
                            )}
                            <div className="container-right">
                                <div className="box">
                                    <p>
                                        Want to get in touch with{" "}
                                        {brandData.name}?
                                    </p>
                                    <p className="text">
                                        {t("Leave a message below.")}
                                    </p>
                                </div>
                                {!formSubmitted ? (
                                    <div className="container-form">
                                        {userSType !== "buyer" && (
                                            <>
                                                <div>
                                                    <label>Your name</label>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        required
                                                    />
                                                    {nameError && (
                                                        <span className="error">
                                                            {nameError}
                                                        </span>
                                                    )}
                                                </div>
                                                <div>
                                                    <label>Your email</label>
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        required
                                                    />
                                                    {emailError && (
                                                        <span className="error">
                                                            {emailError}
                                                        </span>
                                                    )}
                                                </div>
                                                <div>
                                                    <label>
                                                        Your store/e-commerce
                                                        name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="store_name"
                                                        id="store_name"
                                                        required
                                                    />
                                                    {storeNameError && (
                                                        <span className="error">
                                                            {storeNameError}
                                                        </span>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                        <div>
                                            <label>Message</label>
                                            <textarea
                                                placeholder="Hi, we're interested in exploring new brands and products for our store. Could you share your latest line sheet with us?"
                                                rows={5}
                                                name="message"
                                                id="message"
                                            ></textarea>
                                            {messageError && (
                                                <span className="error">
                                                    {messageError}
                                                </span>
                                            )}
                                        </div>
                                        <div>
                                            {sendError &&
                                                sendError.status &&
                                                sendError.data &&
                                                (sendError.status !== 422 ? (
                                                    <span className="error">
                                                        There was an error
                                                        sending your message.
                                                        Please try again later.
                                                    </span>
                                                ) : (
                                                    Object.entries(
                                                        sendError.data.errors
                                                    ).map(([key, errors]) => (
                                                        <span
                                                            key={key}
                                                            className="error"
                                                            style={{
                                                                marginBottom:
                                                                    "3px",
                                                            }}
                                                        >
                                                            {errors[0]}
                                                        </span>
                                                    ))
                                                ))}
                                        </div>
                                        <button
                                            className="btn-send"
                                            type="button"
                                            onClick={this.sendForm}
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting
                                                ? "Sending..."
                                                : "SEND"}
                                        </button>
                                    </div>
                                ) : (
                                    <div
                                        className="container-success"
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                        }}
                                    >
                                        <img
                                            src={checkCircle}
                                            alt="Success"
                                            width={77}
                                            height={77}
                                        />
                                        <p>
                                            {t(
                                                `Thank you for reaching out to ${brandData.name}!`
                                            )}
                                            <br />
                                            Your message has been successfully
                                            sent.
                                            <br />
                                            The brand will get back to you
                                            shortly.
                                        </p>
                                        <button
                                            onClick={() =>
                                                (window.location.href =
                                                    "/brands")
                                            }
                                        >
                                            FIND OTHER BRANDS
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="brandDetailsContainer">
                                <div>
                                    <h2>About {brandData.name}</h2>
                                </div>
                                <div className="brandDetails">
                                    <div
                                        className="brandDescription"
                                        dangerouslySetInnerHTML={{
                                            __html: brandData.the_brand
                                                .landing_modal_text,
                                        }}
                                    />

                                    <div className="brandDetailsList">
                                        {brandData.the_brand.brand_from && (
                                            <div className="blockInfo brand">
                                                <h4 className="title">
                                                    BRAND FROM
                                                </h4>
                                                <p className="text">
                                                    {
                                                        brandData.the_brand
                                                            .brand_from
                                                    }
                                                </p>
                                            </div>
                                        )}

                                        {brandData.the_brand
                                            .year_established && (
                                            <div className="blockInfo brand">
                                                <h4 className="title">
                                                    YEAR ESTABLISHED
                                                </h4>
                                                <p className="text">
                                                    {
                                                        brandData.the_brand
                                                            .year_established
                                                    }
                                                </p>
                                            </div>
                                        )}

                                        {brandData.the_brand
                                            .formatted_segments && (
                                            <div className="blockInfo brand">
                                                <h4 className="title">
                                                    SEGMENTS
                                                </h4>
                                                <p className="text">
                                                    {
                                                        brandData.the_brand
                                                            .formatted_segments
                                                    }
                                                </p>
                                            </div>
                                        )}

                                        {brandData.the_brand
                                            .formatted_categories && (
                                            <div className="blockInfo brand">
                                                <h4 className="title">
                                                    CATEGORIES
                                                </h4>
                                                <p className="text">
                                                    {
                                                        brandData.the_brand
                                                            .formatted_categories
                                                    }
                                                </p>
                                            </div>
                                        )}

                                        {(brandData.the_brand.site_url ||
                                            brandData.the_brand.facebook_url ||
                                            brandData.the_brand.instagram_url ||
                                            brandData.the_brand.tiktok_url) && (
                                            <div className={`blockInfo`}>
                                                <h4 className="title">LINKS</h4>

                                                {brandData.the_brand
                                                    .site_url && (
                                                    <a
                                                        href={
                                                            brandData.the_brand
                                                                .site_url
                                                        }
                                                        target="_blank"
                                                        className="icon"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faGlobe}
                                                        />
                                                    </a>
                                                )}

                                                {brandData.the_brand
                                                    .facebook_url && (
                                                    <a
                                                        href={
                                                            brandData.the_brand
                                                                .facebook_url
                                                        }
                                                        target="_blank"
                                                        className="icon"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faFacebook}
                                                        />
                                                    </a>
                                                )}

                                                {brandData.the_brand
                                                    .instagram_url && (
                                                    <a
                                                        href={
                                                            brandData.the_brand
                                                                .instagram_url
                                                        }
                                                        target="_blank"
                                                        className="icon"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faInstagram}
                                                        />
                                                    </a>
                                                )}

                                                {brandData.the_brand
                                                    .tiktok_url && (
                                                    <a
                                                        href={
                                                            brandData.the_brand
                                                                .tiktok_url
                                                        }
                                                        target="_blank"
                                                        className="icon"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faTiktok}
                                                        />
                                                    </a>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="brandStyleContainer">
                                <h2>Brand Style</h2>

                                <div className="imagesContainer">
                                    {brandStyleImages[0] && (
                                        <img
                                            src={brandStyleImages[0].image_285}
                                            className="highlightImage"
                                        />
                                    )}

                                    <div className="otherImages">
                                        {brandStyleImages &&
                                            brandStyleImages
                                                .slice(1, 5)
                                                .map((item) => {
                                                    return (
                                                        <img
                                                            src={item.image_285}
                                                        />
                                                    );
                                                })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="footerImageContainer">
                        <h3>Supported by</h3>
                        <Link to="/project/special/itastyle">
                            <img
                                src="/images/itastyle.png"
                                alt="Italian Trade Agency"
                            />
                        </Link>
                    </div>
                </section>

                {!this.state.shouldShowSendMessageForm && (
                    <div
                        className="floatingMessageButtonContainer"
                        title="Send a message"
                        onClick={() => {
                            this.setState({
                                shouldShowSendMessageForm: true,
                            });

                            window.scrollTo({ top: 635, behavior: "smooth" });
                        }}
                    >
                        <FontAwesomeIcon icon={faComment} />
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(withRouter(TheBrandComponentLightTemplate));
