import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import TagManager from '@sooro-io/react-gtm-module'

function Analitycs(props) {
    
  useEffect(()=>{
        
      let tagManagerArgs = {
        gtmId: "GTM-5PD75F5Q", // DEV
        dataLayer: {}
      }
      if ( window.location.hostname === "blancfashion.com" ) {
        tagManagerArgs.gtmId = "GTM-NB45Q7H"; // PROD
      }
      TagManager.initialize(tagManagerArgs)
    }, [])

  return (
    <div />
  );
}

export default withRouter(Analitycs);
